<template>
  <i
      v-c-tooltip="tooltip"
      :class="iconCode"
      aria-hidden="true"></i>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'This is a description'
    },
    placement: {
      type: String,
      default: 'right'
    },
    iconCode: {
      type: String,
      default: 'fa fa-question-circle-o'
    }
  },
  computed: {
    tooltip() {
      const html = this.text;
      const div = document.createElement('div');
      div.innerHTML = html;
      const text = div.textContent || div.innerText || '';

      return {
        content: text.length ? text : 'This is a description',
        placement: this.placement
      }
    }
  },
}
</script>

<style lang="scss">
.fa-question-circle-o {
  height: 14px;
  font-size: 14px;
  /*z-index: 100000;*/
}
</style>